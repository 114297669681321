$(function () {
  var windowWidth = $(window).width();
  if( windowWidth < 769 ){// SP
    // パララックス
    if($('.rellax').length){
      var rellax = new Rellax('.rellax', {
        speed: 1.2,
      });
  }
  }else{// PC
    // パララックス
    if($('.rellax').length){
      var rellax = new Rellax('.rellax', {
        speed: 1.5,
      });
    }
  }

  // メニュー
  $('.gnav_menu').on('click',function(){
    if($('.menu_line').hasClass('active')){
      $('.menu_line').removeClass('active');
      $('.gnav_wrap').removeClass('open');
      $('html').removeClass('html_hidden');
    }
    else {
      $('.menu_line').addClass('active');
      $('.gnav_wrap').addClass('open');
      $('html').addClass('html_hidden');
    }
  });
  $('.gnav_wrap a').on('click', function () {
    $(".menu_line").toggleClass('active');
    $('.gnav_wrap').toggleClass('open');
    $('html').toggleClass('html_hidden');
  })
  $('.gnav_wrap .gnav_inner').on('click', function () {
    $(".menu_line").removeClass('active');
    $('.gnav_wrap').removeClass('open');
    $('html').removeClass('html_hidden');
  })

  // アンカーリンクのずれ修正
  var headerHeight = $('.header').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({scrollTop:position}, 500);
    }, 100);
  }
  $('a[href^="#"]').click(function() {
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - headerHeight;
    $('body,html').stop().animate({scrollTop:position}, 500);
  });

  // header分の余白
  if(!($('.top_header').length)){
    $('main').css("padding-top", headerHeight);
  }

  var topicSwiper = new Swiper(".topic_article_slider", {
    loop: true,
    centeredSlides: true,
    slidesPerView: 1.45,
    spaceBetween: "6%",
    speed: 600,
    autoplay: {
      delay: 3000,
    },
    allowTouchMove: true,
    breakpoints: {
      768: {
        loop: false,
        centeredSlides: false,
        slidesPerView: 4,
        spaceBetween: "3.64%",
        allowTouchMove: false,
      },
    },
  });
  var topReviewSwiper = new Swiper(".review_slider", {
    loop: true,
    centeredSlides: true,
    slidesPerView: 1.67,
    speed: 6000,
    autoplay: {
      delay: 0,
    },
    allowTouchMove: false,
    breakpoints: {
      768: {
        slidesPerView: 2.2,
      },
    },
  });
});

// ページ読み込み後のアニメーション
$(window).on('load', function() {
  $('.first_ani_item').addClass('anistart');
});

// スクロールした時のアニメーション
$(window).on("scroll", function() {
  var scroll = $(window).scrollTop();
  var windowHeight = $(window).height();
  if (scroll > 0){
    $('.header').addClass('fixed');
  }
  else {
    $('.header').removeClass('fixed');
  }

  $('.ani_item').each(function(){
    var position = $(this).offset().top;
    if (scroll > position - windowHeight + 50){
      $(this).addClass('anistart');
    }
  });
});

//「。」を消す
$(document).ready(function() {
    $('.product__tax.caption.rte').text('税込');
});